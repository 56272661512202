import React from 'react';
import PowerBIFrame from '../../Components/PowerBIFrame';
import NoRightClickDiv from '../../Components/NoRightClick';

const Analytics = () => {

    const powerBIUrl = "https://app.powerbi.com/view?r=eyJrIjoiMjUwMDllYzgtN2YyOC00NzI0LThiZWItYzE4NmQ1NDhhOTU3IiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9&pageName=c506d06cd850193b0d54";

    const powerBIUrl2 = "https://app.powerbi.com/view?r=eyJrIjoiYmE2M2NhNzAtZmI2Ny00ZTI1LWI4MjMtNTI0MTNjZDk0MGQ2IiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9&pageName=8286e46583d902e6d06d";

    const powerBIUrl3 = "https://app.powerbi.com/view?r=eyJrIjoiMjUwMDllYzgtN2YyOC00NzI0LThiZWItYzE4NmQ1NDhhOTU3IiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9&pageName=92a70cea99b3e4c20a0c";

     
  return (
    <>
    <div className='Analytics pt-5'>
        <div className='frame'>
          <PowerBIFrame src={powerBIUrl} height="650px"/>
          <div className='frameFooter'></div>
        </div>
      </div>

      <div className='Analytics pt-5'>
        <div className='frame'>
          <PowerBIFrame src={powerBIUrl2} height="650px"/>
          <div className='frameFooter'></div>
        </div>
      </div>

      <div className='Analytics pt-5'>
        <div className='frame'>
          <PowerBIFrame src={powerBIUrl3} height="650px"/>
          <div className='frameFooter'></div>
        </div>
      </div>
    </>
      
    
  )
}

export default Analytics;