import React, { useEffect, useRef } from 'react';

const PowerBIFrame = ({ src, height }) => {
  const iframeRef = useRef(null);

  // useEffect(() => {
  //   const disableRightClick = (e) => {
  //     e.preventDefault();
  //   };

  //   const iframe = iframeRef.current;

  //   if (iframe) {
  //     iframe.addEventListener('contextmenu', disableRightClick);
  //   }

  //   return () => {
  //     if (iframe) {
  //       iframe.removeEventListener('contextmenu', disableRightClick);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleLoad = () => {
  //     const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
  //     iframeDoc.addEventListener('contextmenu', (e) => {
  //       e.preventDefault();
  //     });
  //   };

  //   if (iframeRef.current) {
  //     iframeRef.current.addEventListener('load', handleLoad);
  //   }

  //   return () => {
  //     if (iframeRef.current) {
  //       iframeRef.current.removeEventListener('load', handleLoad);
  //     }
  //   };
  // }, []);



  return (
    <div className='d-flex justify-content-center'>
        <iframe
        ref={iframeRef}
        src={src}
        width="100%"
        height={height}
        style={{ border: 'none' }}
        title="Power BI Graph"
        />
    </div>
  );
};

export default PowerBIFrame;
